@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}
a {
  text-decoration: none;
}

/* STORE STYLING  */
.store-container {
  background: rgb(248, 249, 216);
  background: linear-gradient(
    90deg,
    rgba(248, 249, 216, 1) 42%,
    rgba(238, 157, 237, 1) 100%
  );
  height: 100vh;
  width: 100%;
}
.scroll-container {
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #000;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.logo {
  margin: auto;
  margin-top: 35px;
  background-color: #000;
  color: #fff;
  font-size: 35px;
  font-weight: 600;
  padding: 12px 25px;
  border-radius: 14px;
  width: 20%;
  text-align: center;
  font-family: "Marcellus", serif;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding: 45px 50px;
}
.card {
  background-color: #fff;
  border-radius: 11px;
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
h1 {
  margin-top: -10px;
  background-color: #000;
  color: #fff;
  padding: 15px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}
@media only screen and (max-width: 991px) {
  .logo {
    width: 80%;
    font-size: 30px;
  }
  .grid {
    grid-template-columns: 1fr;
    padding: 45px 15px;
  }
  h1 {
    font-size: 15px;
  }
}

.home-container {
  background: rgb(248, 249, 216);
  background: linear-gradient(
    90deg,
    rgba(248, 249, 216, 1) 42%,
    rgba(238, 157, 237, 1) 100%
  );
  height: 100vh;
  width: 100%;
}
.home-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 70px;
}
@media only screen and (max-width: 991px) {
  .home-container img {
    width: 280px;
    height: 38px;
  }
}